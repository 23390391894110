import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button } from "../src/gatsby-theme-docz/components/Button/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "öffentliche-aufträge"
    }}>{`Öffentliche Aufträge`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Kunde`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Grassi Museum Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/K/MSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2,5 Mio€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Uni Leipzig -Veterinärmedizinisches Institut`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`620 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Uni Leipzig - Automatenlabor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Uni Leipzig - Zentrallabor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`320 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Uni Leipzig - Institut für klinische Immunologie undTransfussionsmedizin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`L/K/Reinraum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Uni Leipzig - Institut IZKF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LVA Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`140 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Arbeitsamt Riesa`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/K/MSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`550 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finanzamt Grimma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/MSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`125 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bundesamt für Geodäsie und Kartographie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`L/K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gauck-Behörde Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BESA Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bundessortenamt Wurzen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`105 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Heeresunteroffiziersschule Delitzsch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`440 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Truppenunterkunft Lindhardt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Theodor-Körner-Kaserne Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`115 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bundeswehr Holzhausen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Jugendstrafanstalt Regis-Breitingen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,5 Mio€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Justizvollzugsanstalt Waldheim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Justizvollzugskrankenhaus Leipzig - Küche, Verwaltung, Hafthaus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`155 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bereitschaftspolizei Leipzig Haus 7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`260 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bereitschaftspolizei Leipzig Haus 1 - Einlasskontrollgebäude und FW-Ltg.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`285 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Polizeirevier Mitte Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Polizeirevier Oschatz`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L/K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Polizeirevier Wurzen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Feuerwache Leipzig, Lauchstädter Str.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`155 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Feuerwehrgerätehaus Lützschena`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Feuerwehrgerätehaus Zschadraß`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Stadion Eilenburg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L/MSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`250 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sporthalle Hausdorf (mit Energiekonzept)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gymnasium und Turnhalle Borna`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`205 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sporthalle Lessing-Gymnasium Döbeln`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/MSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`350 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sporthalle General-Olbricht-Kaserne`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sporthalle Holzhausen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sporthalle 41. Schule Leipzig mit Schwingbodenheizung`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sporthalle Trebsen mit Schwingbodenheizung`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sport- und Freizeitzentrum Taucha`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Muldental-Sporthalle Grimma, GGI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sporthalle Colditz`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Berufsschulzentrum Wurzen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/K/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Berufsschulzentrum Credner Str., Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`290 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Förderschule Grimma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Landratsamt Grimma Haus 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rathaus Grimma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/MSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`175 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Stadtteilzentrum "Anker" e.V. Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bürgerzentrum Nerchau`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Zoo Leipzig - Schweizer Haus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`140 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Zoo Leipzig - Lippenbärenanlage FW-Leitung`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Zoo Leipzig - Erschließung Bullenstall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Asylbewerberheim Mobendorf`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`140 T€`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Legende: H=Heizung, S=Sanitär, L=Lüftungsinstallation, K=Kälte- bzw. Klimainstallationen, MSR=Regeltechnische Anlagen`}</strong></p>
    <Button mdxType="Button" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      